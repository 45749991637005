<template>
  <div>
    <div>
      <b-badge
        :variant="
          status.value === selectedStatus ? 'primary' : 'light-secondary '
        "
        v-for="status in statuses"
        :key="status.id"
        pill
        :class="
          status.value === selectedStatus
            ? 'mr-1 mb-1 cursor-pointer badge-padding'
            : 'mr-1 mb-1 cursor-pointer badge-padding badge-maintenance'
        "
        @click="statusSelection(status.value, false)"
      >
        {{ $t(status.name) }}
      </b-badge>
    </div>
    <b-card class="mt-306">
      <MaintenanceReminderList
        :selectedStatus="selectedStatus"
      ></MaintenanceReminderList>
    </b-card>
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BButton,
  BTooltip,
  VBTooltip,
  BFormInput,
  BRow,
  BCol,
  BBadge,
  BModal
} from "bootstrap-vue";

import MaintenanceReminderList from "./MaintenanceReminderList.vue";
import constants from "@/utils/constants";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,
    BTooltip,
    VBTooltip,
    MaintenanceReminderList,

    BFormInput,
    BRow,
    BCol,
    BBadge,

    BModal
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  data() {
    return {
      showFiltermenu: false,

      statuses: [
        { id: 1, value: "ALL", name: this.$t("maintenance.All") },
        { id: 2, value: "UPCOMING", name: this.$t("maintenance.Upcoming") },
        { id: 3, value: "DUE", name: this.$t("maintenance.Due") },
        { id: 4, value: "COMPLETED", name: this.$t("maintenance.Completed") }
      ],

      selectedStatus: "ALL"
    };
  },
  watch: {
    $route(to, from) {
      if (this.$route.query.tab) {
        this.selected_tab = this.$route.query.tab;
        this.statusSelection(this.$route.query.tab, true);
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onWindowResize);
  },
  mounted() {
    this.onWindowResize();
    // window.addEventListener("resize", this.onWindowResize);
    if (this.$route.query.tab) {
      this.selected_tab = this.$route.query.tab;
      this.statusSelection(this.$route.query.tab, true);
    } else {
      this.$router
        .replace({
          query: this.selectedStatus
            ? { ...this.$route.query, tab: this.selectedStatus }
            : { ...this.$route.query }
        })
        .catch(() => {});
    }
  },
  methods: {
    onWindowResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth > 820) {
        this.showFiltermenu = true; //Mobile
      } else {
        this.showFiltermenu = false; //Desktop
      }
    },

    statusSelection(status, isRedirect) {
      this.selectedStatus = status ? status : "ALL";

      // this.$router.push({
      //   name: "maintenance-reminder-list",
      //   query: { tab: status }
      // });

      const selectedStatusData = this.statuses.find((s) => s.value === status);
      this.$route.meta.breadcrumb = JSON.parse(
        JSON.stringify(constants.MAINTENANCE_REMINDERS_BREADCRUMB)
      );
      let breadcrumb = {
        text: selectedStatusData.name,
        index: selectedStatusData.value,
        active: true
      };
      this.$route.meta.breadcrumb.push(breadcrumb);
      this.$router
        .replace({
          query: this.selectedStatus
            ? { ...this.$route.query, tab: this.selectedStatus }
            : { ...this.$route.query }
        })
        .catch(() => {});
    }
  }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style lang="scss" scoped>
.badge-padding {
  padding: 10px 15px;
}
@media only screen and (max-width: 991px) {
  .notification-per-page {
    margin-left: 0;
    margin-right: 0px;
    width: 100%;
  }
  .mobile-margin-top1 {
    margin-top: 15px;
  }
  .search-display-1 {
    .search-filter {
      display: block !important;
      min-width: 100%;
    }
  }
  .filter-card {
    width: 100%;
    max-width: 300px;
    position: absolute;
    top: 52px;
    z-index: 9;
  }
}
</style>
<style lang="scss" scoped>
.mt-306 {
  height: calc(100vh - 170px);
  overflow-y: hidden;
}

body.dark-layout .badge.badge-light-secondary.badge-maintenance {
  background-color: var(--secondary) !important;
  color: #fff !important;
}
</style>
